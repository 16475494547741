import React from 'react'
import Subhero from '../../Components/About/components/Subhero'
import '../style/About.css'
import AboutBST from '../../Components/About/components/AboutBST'
import KnowMore from '../../Components/About/components/KnowMore'
import OurTeam from '../../Components/About/components/OurTeam'
import WhyBookWithUs from '../../Components/About/components/WhyBookWithUs'
import OurTermsAndCondition from '../../Components/About/components/OurTermsAndCondition'
import Team from '../../Data/About/OurTeam.json'
import WhyUs from '../../Data/About/WhyBookWithUs.json'
import { Helmet } from 'react-helmet'
import HelmetForSEO from '../../Components/HelmetForSEO'
const About = () => {
  return (<>
<HelmetForSEO
  title="About Us - Big Sky Treks" 
  discription="Learn more about Big Sky Treks, our mission, and our team. We specialize in guided treks across Nepal's breathtaking landscapes, including the Everest Base Camp and Annapurna Circuit." 
  url="/about" 
  keywords="Big Sky Treks, about us, trekking, Nepal, guided treks, adventure travel, Everest Base Camp, Annapurna Circuit, Gokyo Lake, Everest View Trek, hiking, mountain trekking, travel company" 
/>
    <div className='about-us'>
<Subhero image={"/Image/about/about-hero.webp"} title="About Us"/>
<div className="main-content">
  <div className="sub-main">


  <div className="about-bst">
  
    <AboutBST/>
  
    <KnowMore/>
  </div>
<div className="our-teams">
  <h2 className="heading">Our Team</h2>
  <div className="card-wrapper">
    {
      Team.map((key,index)=>{
        return <OurTeam image={key.image} name={key.name} post={key.post} description={key.discription}/>
      })
    }
  </div>
</div>
  <div className="why-book-with-us">
    <h2 className="heading">Why Book With Us</h2>
    {
       WhyUs.map((key ,index)=>{
        return <WhyBookWithUs heading={key.heading} paragraph={key.discription}/>
       })
      }
    
  </div>
  <div className="our-terms-and-condition">
    <OurTermsAndCondition/>
  </div>
</div>
</div>
    </div>
    </>
  )
}

export default About