import React from 'react'
import Tempelate from "../../Components/Trekking/components/Template"
const Trekking = () => {
  return (
    <div>
      
        <Tempelate/>
    </div>
  )
}

export default Trekking