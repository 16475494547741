import React from 'react'
import Subhero from '../../About/components/Subhero'
import { useParams } from 'react-router-dom'
import Region from '../../../Data/Trekking/trek.json'
import Error from '../../Error/components/Error'
import Sliding from '../../Home/components/Slider/Sliding'
import SubotherTempelate from '../../OtherActivities/components/SubotherTempelate'

const RegionTrek = () => {
  let params=useParams(); 
  const URRL = Region[params.regiontrek]?.url;
  console.log(URRL)
  if (!URRL) {
    return <div><Error/></div>;
  }

  const Toggle = (subOther) => {
    return subOther ? (
      <div className='bg-accent'>
      <h2 className="heading sliding-heading">{subOther.heading}</h2>
      <Sliding array={subOther.activities} />
      </div>
    ) : <>
<SubotherTempelate/>
    </>;
  };
  return (
    <div>
        <Subhero title={Region[params.regiontrek]?.subhero.title} image={Region[params.regiontrek]?.subhero.image}/>
        <div className="main-content">
      <div className="sub-main">
      <div className="overview">
        <h2 className="heading">
          {Region[params.regiontrek]?.overview.heading}
        </h2>
        <p className="format" dangerouslySetInnerHTML={{__html:Region[params.regiontrek]?.overview.paragraph}}/>

      </div>
        </div>
      {Toggle(Region[params.regiontrek].subotheractivities)}
        </div>
        
    </div>
  )
}

export default RegionTrek