import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../Error/components/Error";
import Other from "../../../Data/OtherActivities/Other.json";
import Subhero from "../../About/components/Subhero";
import ContactForm from "../../Trekking/components/ContactForm";
import HelmetForSEO from "../../HelmetForSEO";
const SubotherTempelate = () => {
  const params = useParams();
  let HTMLrender = () => {
    return (
      <>
        <HelmetForSEO
          title={`${
            Other[params.other][params.subother].subhero.title
          } - Big Sky Treks`}
          discription={Other[params.other][params.subother].seo.description}
          keywords={Other[params.other][params.subother].seo.keywords}
          url={`/other-activities/${params.other}/${params.subother}`}
        />
        <Subhero
          title={Other[params.other][params.subother].subhero.title}
          image={Other[params.other][params.subother].subhero.image}
        />
        <div className="main-content">
          <div className="sub-main">
            <div className="overview">
              <h2 className="heading">
                {Other[params.other][params.subother].overview.heading}
              </h2>
              <p
                className="format"
                dangerouslySetInnerHTML={{
                  __html:
                    Other[params.other][params.subother].overview.paragraph,
                }}
              />
            </div>
            <div className="facts">
              <h2 className="heading">
                {Other[params.other][params.subother]["trip-facts"].heading}
              </h2>
              {Other[params.other][params.subother]["trip-facts"].facts &&
                Other[params.other][params.subother]["trip-facts"].facts.map(
                  (facts, index) => {
                    return (
                      <div
                        className="format"
                        key={index}
                        dangerouslySetInnerHTML={{ __html: facts }}
                      />
                    );
                  }
                )}
            </div>
            <div className="programs">
              {Other[params.other][params.subother].programs &&
                Other[params.other][params.subother].programs.map(
                  (program, index) => {
                    return (
                      <div key={index}>
                        <h2 className="heading">{program.heading}</h2>
                        {program.days.map((day, index1) => {
                          return (
                            <div key={index1}>
                              <h3 style={{ fontSize: "22px" }}>
                                {day.heading}
                              </h3>
                              <div
                                className="image"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  gap: "10%",
                                }}
                              >
                                <img
                                  src={day.image}
                                  alt=""
                                  style={{ width: "50%" }}
                                />
                              </div>
                              <div
                                className="format"
                                dangerouslySetInnerHTML={{
                                  __html: day.paragraph,
                                }}
                              />

                              <br />
                            </div>
                          );
                        })}
                      </div>
                    );
                  }
                )}
            </div>
            <div className="contact">
              <h2 className="heading">Book Now</h2>
              <ContactForm />
            </div>
          </div>
        </div>
      </>
    );
  };
  let Checking = () => {
    return Other[params.other][params.subother] == undefined ? (
      <Error />
    ) : (
      <>{HTMLrender()}</>
    );
  };

  return <div>{Checking()}</div>;
};

export default SubotherTempelate;
