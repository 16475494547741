import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const HelmetForSEO = ({
  title,
  discription,
  url,
  image="https://bigskytreksnepal.com/Image/Nav/big-sky-treks-logo.jpg",
  keywords,
  author = 'Big Sky Treks',
  siteName = 'bigskytreksnepal.com',
  twitterHandle = '@bishow8848',
  canonicalUrl=`https://bigskytreksnepal.com${url}`,
  viewport = 'width=device-width, initial-scale=1.0',
  robots = 'index, follow',
  structuredData
}) => {
  const defaultStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    'url': url,
    'name': title,
    'author': {
      '@type': 'Person',
      'name': author,
    },
    'publisher': {
      '@type': 'Organization',
      'name': siteName,
    },
    'image': image,
    'discription': discription,
  };

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="discription" content={discription} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />

      {/* Viewport Meta Tag */}
      <meta name="viewport" content={viewport} />

      {/* Robots Meta Tag */}
      <meta name="robots" content={robots} />

      {/* Canonical Link */}
      {<link rel="canonical" href={canonicalUrl} />}

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:discription" content={discription} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content={siteName} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:discription" content={discription} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:site" content={twitterHandle} />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData || defaultStructuredData)}
      </script>
    </Helmet>
  );
};

HelmetForSEO.propTypes = {
  title: PropTypes.string.isRequired,
  discription: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.string,
  keywords: PropTypes.string,
  author: PropTypes.string,
  siteName: PropTypes.string,
  twitterHandle: PropTypes.string,
  canonicalUrl: PropTypes.string,
  viewport: PropTypes.string,
  robots: PropTypes.string,
  structuredData: PropTypes.object, // Prop for custom structured data
};

export default HelmetForSEO;
