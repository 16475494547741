import React from "react";
import Subhero from "../../About/components/Subhero";
import { useParams } from "react-router-dom";
import Other from "../../../Data/OtherActivities/Other.json";
import Error from "../../Error/components/Error";
import Cards from "../../Home/components/Cards";
import SubotherTempelate from "./SubotherTempelate";
import Sliding from "../../Home/components/Slider/Sliding";
import HelmetForSEO from "../../HelmetForSEO";

const OtherTempelate = () => {
  let params = useParams();
  const URRL = Other[params.other]?.url;
  console.log(URRL);
  if (!URRL) {
    return (
      <div>
        <Error />
      </div>
    );
  }

  const Toggle = (subOther) => {
    return subOther ? (
      <div className="bg-accent">
        <h2 className="heading sliding-heading">{subOther.heading}</h2>
        <Sliding array={subOther.activities} />
      </div>
    ) : (
      <>
        <SubotherTempelate />
      </>
    );
  };
  return (
    <div>
      <HelmetForSEO
        url={`/other-activities/${params.other}`}
        title={`${Other[params.other]?.subhero.title} - Big Sky Treks`}
        discription={Other[params.other]?.seo.description}
        keywords={Other[params.other]?.seo.keywords}
      />
      <Subhero
        title={Other[params.other]?.subhero.title}
        image={Other[params.other]?.subhero.image}
      />
      <div className="main-content">
        <div className="sub-main">
          <div className="overview">
            <h2 className="heading">{Other[params.other]?.overview.heading}</h2>
            <p
              className="format"
              dangerouslySetInnerHTML={{
                __html: Other[params.other]?.overview.paragraph,
              }}
            />
          </div>
        </div>
        {Toggle(Other[params.other].subotheractivities)}
      </div>
    </div>
  );
};

export default OtherTempelate;
