import React from 'react';
import { Link } from 'react-router-dom';
import '../style/AdCard.css'; // Import a CSS file for styling

const AdCard = () => {
  return (
    <div className="ad-card-alt">
      <div className="ad-card-alt-header">
        <h2>🏞️ Explore Nepal’s Wonders! 🏞️</h2>
      </div>
      <div className="ad-card-alt-body">
        <p><strong>Experience the Best of Nepal with Our Top Treks</strong></p>
        <ul>
          <li>Everest Base Camp Trek 🏔️</li>
          <li>Gokyo Lake Trek 💧</li>
        </ul>
        <p>Includes:</p>
        <ul>
          <li>Expert Guides</li>
          <li>Comfortable Lodging</li>
        </ul>
      </div>
      <div className="ad-card-alt-footer">
        <a href="#QuickInquary" className="btn-book-alt">Book Now</a>
        <p className="contact-info-alt"><Link to="https://api.whatsapp.com/send/?phone=%2B9779846139572&text&type=phone_number&app_absent=0">Whatshapp Us</Link> | <Link to="mailto:bigskytreks@gmail.com">Email Us</Link></p>
      </div>
    </div>
  );
};

export default AdCard;
