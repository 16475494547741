import React from 'react'
import Button from '../Button'
import "../../style/ExploreMore/ExpoloreMore.css"

import Cards from '../Cards'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../style/Slider/Sliding.css"
import { GrCaretPrevious } from "react-icons/gr";
import { GrCaretNext } from "react-icons/gr";

const NextArrow = ({ onClick }) => {
  return (
<div class="next round" onClick={onClick}><GrCaretNext/></div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
<div class="previous round" onClick={onClick}><GrCaretPrevious/></div>

  );
};
const Sliding = ({array}) => {
   
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    className: "center",
    centerPadding: "60px",
    centerMode: true,
    autoplay: true,
    speed: 200,
    autoplaySpeed: 5000,
    cssEase: "linear",
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1243,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          fade: false
        }
      },
      {
        breakpoint: 902,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          fade: true
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true,
          dots: true,
        }
      }
    ]
  };
  return (
  <div className='expolore-more'>
    <div className='upper-div'>

        <div className='explore-card'>
    <Slider {...settings}>
 
      {array.map((item, index) => {
          return (
              <div className="cmd">
              <Cards
                key={index}
                link={item.link}
                image={item.image}
                name={item.name}
                days={item.days}
              />
              </div>

              
            )
        })}

      </Slider>
        </div>

    </div>

    </div>
  )
}

export default Sliding
