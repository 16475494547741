import React from 'react'
import Subhero from '../../Components/About/components/Subhero'
import SideBar from '../../Components/Contact/Components/SideBar'
import ContactUS from '../../Data/Conatact/Contact.json'
import ContactForm from '../../Components/Trekking/components/ContactForm'
import '../style/Contact.css'
import { Helmet } from 'react-helmet'
import HelmetForSEO from '../../Components/HelmetForSEO'
const Contact = () => {

  return (<>
<HelmetForSEO
  title="Contact Us - Big Sky Treks" 
  description="Get in touch with Big Sky Treks for any inquiries or to plan your next adventure. We specialize in guided treks across Nepal's breathtaking landscapes, including the Everest Base Camp and Annapurna Circuit." 
  url="/contact" 
  keywords="Big Sky Treks, contact us, trekking, Nepal, guided treks, adventure travel, Everest Base Camp, Annapurna Circuit, Gokyo Lake, Everest View Trek, hiking, mountain trekking, travel company" 
/>

    <Subhero title={ContactUS.subHero.heading} image={ContactUS.subHero.image}/>
    <div className="main-content">
   <div className="sub-main">
  <h2 className="heading">{ContactUS.heading}</h2>
  <div className="top">
  <div className="left">
  <SideBar/>
  </div>
  <div className="right">
  <ContactForm/>
  </div>
  </div>
   </div>
  <div className="buttom" style={{marginTop:"50px"}}>
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8468.814340191955!2d83.96203617476074!3d28.20783286620921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3995951db872613f%3A0xa95a7da2c9736193!2sBig%20Sky%20Treks%2C%20Nepal!5e0!3m2!1sen!2snp!4v1719329220601!5m2!1sen!2snp" width="100%" height="500" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
    </div>
  </>
  )
}

export default Contact