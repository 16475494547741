import React, { useEffect, useState } from "react";
import Subhero from "../../About/components/Subhero";
import Trekking from "../../../Data/Trekking/Trekking.json";
import { useParams } from "react-router-dom";
import DetailItenary from "./DetailItenary";
import "../style/Tempelate.css";
import ContactForm from "./ContactForm";
import GeneralInfo from "./GeneralInfo";
import Error from "../../Error/components/Error";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx";
import { Helmet } from "react-helmet";
import HelmetSeo from "./HelmetSeo";
import HelmetForSEO from "../../HelmetForSEO";
import AdCard from "./AdCard";

const Template = () => {
  const [showLine, setShowLine] = useState(false);

  const ToggleLine = () => {
    return setShowLine(!showLine);
  };

  const params = useParams();
  const URRL = Trekking[params.id]?.url;

  if (!URRL) {
    return (
      <div>
        <Error />
      </div>
    );
  }

  return (
    <>

      <HelmetForSEO
        title={`${Trekking[params.id]?.subhero.title} - Big Sky Treks`}
        discription={Trekking[params.id].seo.discription.content}
        url={`trekking/${params.id}`}
        keywords={Trekking[params.id].seo.keywords.map((key) => {
        return ` ${key}`;
        })}
      />
      <Subhero
        title={Trekking[params.id]?.subhero.title}
        image={Trekking[params.id]?.subhero.image}
      />
      <div className="main-content trkking-tempelate-main-content">
        <div className="trekking-tempelate-side-nav side-nav-desktop">
          <div className="trekking-cards">
            <a href="#GeneralInfo">General Info</a>
            <a href="#Overview">Overview</a>
            <a href="#TrekHighlight">Trek Highlight</a>
            <a href="#OutlineItenary">Outline Itinerary</a>
            <a href="#DetailItenary">Detail Itinerary</a>
            <a href="#IncludeExclude">Include Exclude</a>
            <a href="#QuickInquary">Quick Inquiry</a>
          </div>
        </div>
        <div className="trekking-tempelate-side-nav-mobile side-nav-mobile">
          <div className="line">
            {showLine ? (
              <RxCross1 onClick={() => setShowLine(!showLine)} />
            ) : (
              <GiHamburgerMenu onClick={() => setShowLine(!showLine)} />
            )}
          </div>
          {showLine ? (
            <div className="mobile-trekking-cards">
              <a href="#GeneralInfo" onClick={ToggleLine}>
                General Info
              </a>
              <a href="#Overview" onClick={ToggleLine}>
                Overview
              </a>
              <a href="#TrekHighlight" onClick={ToggleLine}>
                Trek Highlight
              </a>
              <a href="#OutlineItenary" onClick={ToggleLine}>
                Outline Itinerary
              </a>
              <a href="#DetailItenary" onClick={ToggleLine}>
                Detail Itinerary
              </a>
              <a href="#IncludeExclude" onClick={ToggleLine}>
                Include Exclude
              </a>
              <a href="#QuickInquary" onClick={ToggleLine}>
                Quick Inquiry
              </a>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="sub-main-content">
          <div className="info">
            <h2 className="heading">
              {Trekking[params.id].GeneralInfo.heading}
            </h2>
            <div className="info-cards" id="GeneralInfo">
              <GeneralInfo Object={Trekking} Param={params} />
            </div>
          </div>
          <div className="overView" id="Overview">
            <h2 className="heading">{Trekking[params.id]?.overview.heading}</h2>
            <div
              className="format"
              dangerouslySetInnerHTML={{
                __html: Trekking[params.id]?.overview.paragraph,
              }}
            ></div>
          </div>
          <div className="trek-highlights" id="TrekHighlight">
            <h2 className="heading">
              {Trekking[params.id]?.trekHighlights.heading}
            </h2>
            <ol>
              {Trekking[params.id]?.trekHighlights.paragraph.map(
                (item, index) => (
                  <li
                    className="format"
                    dangerouslySetInnerHTML={{ __html: item }}
                    key={index}
                    style={{ fontSize: "20px", marginTop: "5px" }}
                  ></li>
                )
              )}
            </ol>
          </div>
          <div className="outline-itenary" id="OutlineItenary">
            <h2 className="heading">
              {Trekking[params.id]?.outlineItenary.heading}
            </h2>
            <ol style={{ listStyle: "none" }}>
              {Trekking[params.id]?.outlineItenary.paragraph.map(
                (item, index) => (
                  <li
                    className="format"
                    dangerouslySetInnerHTML={{ __html: item }}
                    key={index}
                    style={{ fontSize: "20px", marginTop: "5px" }}
                  />
                )
              )}
            </ol>
          </div>
          <div className="detail-itenary" id="DetailItenary">
            <h2 className="heading">
              {Trekking[params.id].detailItenary.heading}
            </h2>
            <DetailItenary Trekking={Trekking} params={params} />
          </div>
          <div className="include-exclude" id="IncludeExclude">
            <h2 className="heading">
              {Trekking[params.id]?.includeExclude.heading}
            </h2>
            <div className="include">
              <h2>{Trekking[params.id]?.includeExclude.include.heading}</h2>
              <ul>
                {Trekking[params.id]?.includeExclude.include.lists &&
                  Trekking[params.id]?.includeExclude.include.lists.map(
                    (item, index) => (
                      <li
                        className="format"
                        style={{ fontSize: "20px" }}
                        key={index}
                      >
                        {item}
                      </li>
                    )
                  )}
              </ul>
            </div>
            <div className="exclude">
              <h2>{Trekking[params.id]?.includeExclude.exclude.heading}</h2>
              <ul>
                {Trekking[params.id]?.includeExclude.exclude.lists &&
                  Trekking[params.id]?.includeExclude.exclude.lists.map(
                    (item, index) => (
                      <li
                        className="format"
                        style={{ fontSize: "20px" }}
                        key={index}
                      >
                        {item}
                      </li>
                    )
                  )}
              </ul>
            </div>
          </div>
          <div className="quick-inquary-form" id="QuickInquary">
            <h2 className="heading">
              {Trekking[params.id]?.quickInquaryForm.heading}
            </h2>
            <ContactForm />
          </div>
        </div>
        <div>
          <AdCard />
        </div>
      </div>
    </>
  );
};

export default Template;
