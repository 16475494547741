import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import Trekking from "../../../Data/Trekking/Trekking.json"
const HelmetSeo = () => {
    let params=useParams()
    let data=Trekking[params.id].seo


    const organizationData = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Big Sky Treks Nepal",
      "url": "https://www.bigskytreksnepal.com",
      "logo": "https://www.bigskytreksnepal.com/images/logo.png",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+977 9846139572",
        "contactType": "Customer Service"
      },
      "sameAs": [
        "https://www.facebook.com/Bigskytreks",
        "https://www.instagram.com/bigskytreks/"
      ]
    };
  
    const breadcrumbList = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.bigskytreksnepal.com"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Trekking",
          "item": "https://www.bigskytreksnepal.com/trekking"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "Everest Base Camp",
          "item": `https://www.bigskytreksnepal.com/trekking/${Trekking[params.id]?.url}`
        }
      ]
    };
  
  return (
    <div>
    <Helmet>
      <title>Everest Base Camp</title>
      {<meta name="description" content={`${data.discription.content} && ${data.discription.content}`} />}
      {data.keywords && <meta name='keywords' content={`${data.keywords.map((word)=>`${word}`)}`} />}
      
      <meta name="roborts" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>


    <meta property="og:type" content="article"/>
    <meta property="og:title" content="Everest Base Camp"/>
    { data.discription.content && <meta property="og:description" content={`${data.discription.content}`}/>}
<meta property="og:url" content="https://www.bigskytreksnepal/trekking/everest-base-camp.com"/> 
    {/* <meta property="og:image" content="https://www.bigskytreksnepal.com/"/> image url from site  */}
    <meta property='og:site_name' content='Big Sky Treks'/>
    <meta property='article_tag' content='Everest Base Camp'/>




    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:title" content="Everest Base Camp"/>
    {data.discription.content && <meta name="twitter:description" content={`${data.discription.content}`}/>}
    {/* <meta name="twitter:image" content="https://www.bigskytreksnepal.com/images/twitter-image.jpg"/>  image url from site*/}
    
    

    <script type="application/ld+json">
        {JSON.stringify(organizationData)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbList)}
      </script>
      </Helmet>



    </div>
  )
}

export default HelmetSeo