import React from 'react'
import '../style/OurTeam.css'
import { Link } from 'react-router-dom'
const OurTeam = ({ image, name, post, description,link }) => {
  return (
    
    <div className="employee-card">
      <Link to={link} className='link'>
      <div className="employee-card-wrapper">
        <div className="card">
          <div className="pur-team-image">
            <img src={image} alt={name} />
          </div>
          <div className="content">
            <h3>{name}</h3>
            <h5>{post}</h5>
            <p className='our-team-discription'>{description}</p> 
          </div>
        </div>
      </div>
      </Link>
    </div>
    
  )
}

export default OurTeam