import React from 'react'
import About from '../../../Data/About/AboutBST.json'

const AboutBST = () => {


  return (
    <div>
    <h2 className="heading">{About.heading}</h2>
    <p dangerouslySetInnerHTML={{ __html: About.paragraph }} className='format'/>
    
    </div>
  )
}

export default AboutBST