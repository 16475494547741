import React from "react";
import "../style/Subhero.css";
import Search from "../../Nav/components/Search";
const Subhero = (props) => {
  return (
    <div className="relative" style={{position:"relative"}}>
     <img className="clouds" src="/clouds.png" alt=""/>
    <section
      className="sub-hero"
      style={{ backgroundImage: `url(${props.image})` }}
    >
      <div className="hero-content">
        <Search />

        <h1>{props.title}</h1>
      </div>
    </section>
    </div>
  );
};

export default Subhero;
