import React from "react";
import Search from "../../Nav/components/Search";
import "../style/Hero.css";
const Hero = () => {
  return (
    <>
      <div className="hero">
        <h1>Tailor Made Vacation For Every Style & Intrest</h1>
        <Search/>
      </div>
    </>
  );
};

export default Hero;
