import React from 'react'
import blog from '../../../Data/Blogs/blogs.json'
import BlogsCards from './BlogsCards'


const BlogTempelate = () => {

  return (
    <>
    <section
      className="sub-hero"
      style={{ backgroundImage: `url(https://buffer.com/cdn-cgi/image/w=1000,fit=contain,q=90,f=auto/library/content/images/size/w600/2023/10/free-images.jpg)`}}
    >
      <div className="hero-content">
        <h1>Hello</h1>
      </div>
    </section>
    <div className="main-content">
<div className="sub-main">
<div className="overview">
  <h1 className="heading">{blog.overview.heading}</h1>
  <p className="format" dangerouslySetInnerHTML={{__html:blog.overview.paragraph}}/>
</div>
<div>
  <div className="blog-cards">
<h1 className="heading">
{blog.cards.heading}
</h1>
</div>

<div className="card-wrapper">
  {blog.cards.lists.map((item,index)=>{
  return <BlogsCards 
  link={item.link}
  image={item.image}
  month={item.month}
  day={item.day}
  heading={item.heading}
  discription={item.discription}
  writer={item.writer}
  />
})}
</div>
</div>
</div>
</div>
    </>
  )
}

export default BlogTempelate